import * as React from "react";
import styled from "styled-components";
import FromProg from "../../assets/FromProg.svg";

const TripleImageContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 3em;
  margin-top: 3em;
  /* width: 200%; */
`;

const CircularImage = styled.img`
  border-radius: 50%;
  height: 8em;
  &.optional-image {
    height: min(25%, 8em);
    width: min(25%, 8em);
  }
  /* @media screen and (max-width: 600px) {
    &.optional-image {
        height:4em;
    }
  } */
`;

const TripleImage = () => {
  return (
    <TripleImageContainer>
      <CircularImage src="/about/girolle.jpg" className="optional-image" />
      <CircularImage src="/about/yann.jpg" />
      <CircularImage src="/about/relu.png" className="optional-image" />
    </TripleImageContainer>
  );
};

const PresText = styled.p`
  font-size: 16pt;
  @media screen and (max-width: 480px) {
    font-size: 17px;
  }
`;

const TinyCheese = styled(FromProg)`
  display: inline;
  width: 30px;
  height: 30px;
`;

const TextSignature = styled.p`
  font-size: 18pt;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
`;

const SignatureContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-bottom: auto;
`;
const Signature = () => {
  return (
    <SignatureContainer>
      <TinyCheese />
      <TextSignature>Yann</TextSignature>
    </SignatureContainer>
  );
};

const Bluew = styled.span`
  color: ${({ theme }) => theme.maincolor.color1};
  font-weight: bold;
`;

const PresentationText = () => {
  return (
    <>
      <PresText>
        Hi! I am Yann.
        <br />
        <br />
        I created this website to share two of my passions, which are
        respectively... well Programming and Cheese. Even if these two domains
        can seem at first sight pretty different, they are both universes that I
        try to discover each day a bit more, and this blog is the perfect way to
        share what I learn regularly in both fields.
        <br />
        <br />
        <h3>What I'm into </h3>
        Although my main specialty lies in{" "}
        <Bluew>data engineering and machine learning</Bluew>, I love getting out
        of my comfort zone and play with other tech stacks.
        <br></br>
        <br></br>I value well designed code architectures, this is in my opinion
        the most critical part of software development. I therefore often read
        about clean code, <Bluew>design patterns</Bluew> and different{" "}
        <Bluew>object oriented paradigms.</Bluew>
        <br></br>
        <br></br>
        It's this spirit that pushed me to perform a hard switch to the{" "}
        <Bluew>linux kernel</Bluew>, starting on a vanilla ubuntu machine and
        slowly exploring other distros on VMs. At the moment, I am on arch linux
        for the didactic approach of building your OS from scratch and the
        comprehensive wiki. In my previous positions, I often found myself
        performing regular <Bluew>sysadmin tasks</Bluew> such as setting up ssh
        clusters, vpns or managing users and permissions on linux servers.
        <br></br>
        <br></br>
        In line with the agile principle that favors working programs over
        comprehensive documentation, I like delivering usable software. This is
        why I enjoy occasionally creating small{" "}
        <Bluew>frontend applications</Bluew> in React such as this website to
        provide an interface to my code. As the saying goes: your code is dead
        once no one cares about it anymore, as optimized as it might be.
        <br></br>
        <br></br>
        In order to setup efficient backend infrastructures, I taught myself the
        different <Bluew>cloud computing skills</Bluew> required for my job,
        which in my sense were missing in my studies. I passed the AWS cloud
        architect certification to get them recognized, but believe that this
        website backend represents a more solid evidence of my abilities as one
        can binge study the theory of some of these online certifications,
        without actually deploying any infrastructure.
        <br></br>
        <br></br>
        Finally, you might notice from the resources section that I value an
        optimized workspace and spend quite some time digging for productivity
        tips in my free time: I closely follow the vscode and tmux projects and
        new features.
        <br></br>
        <br></br>
        <h3>What I plan to do later </h3>
        In a few years time, my goal is to become a lead software engineer/
        technical project manager in a dev team of a few engineers. This would
        be the ideal tradeoff between having social interactions and staying
        close to the technical aspect, contributing to the design process and
        translating business requirements into actual features. But for this, I
        still need a few years of experience :)
        <br></br>
        <br></br>

        <h3>What I'm looking for </h3>
        The different positions I have assumed in the past years brought me a
        lot, and let me come up with the following requirements for my next job:
        <br />
        <br />
        <ul>
          <li>
            Work <Bluew>as part of a team</Bluew> as opposed to being a
            self-employed developer{" "}
          </li>
          <li>
            {" "}
            <Bluew>Not being the most experienced</Bluew> developer in the team
          </li>
          <li>
            Tackling a <Bluew>new framework</Bluew> /programing language with
            every new job
          </li>
          <li>
            Be around <Bluew>passionate</Bluew> devs that love keeping up to
            date with the latest technology and having chats about it
          </li>
        </ul>
        <br />I am always curious about new projects! If you are interested in a
        new software adventure or cheese degustation, don't hesitate to contact
        me. You can also download my{" "}
        <a href="https://drive.google.com/file/d/1JYnMOJFu_HdmWuIhev1quSYeflLZG-kx/view?usp=share_link">
          resume
        </a>{" "}
        or check my{" "}
        <a href="https://www.linkedin.com/in/yann-mentha-1482aa192/">
          linkedin
        </a>
        . <br />
        <br />
        Hope to have of your news soon!
        <br />
        <br />
      </PresText>
      <Signature />
    </>
  );
};

export { TripleImage, PresentationText };

import React from "react";
import Layout from "../components/layout";
import PageHeading from "../components/page_heading";
import { TripleImage, PresentationText } from "../components/pages/about";

const AboutPage = () => {
  return (
    <Layout iconName="about" pageWidth="800px">
      <PageHeading headingText="About me" subHeadingText="" />
      <TripleImage />
      <PresentationText />
    </Layout>
  );
};
export default AboutPage;
